.old-soul-project {
    background-image: url('../../assets/images/desktop/project/oldSoulCover.jpg');
    background-size: cover;
    background-position: 0% 100% ;
    background-repeat: no-repeat;    
} 

.lantern-light-kitchen-project{
    background-image: url('../../assets/images/desktop/project/lanternLightKitchenCover.jpg');
    background-size: cover;
    background-position: 0% 50% ;
    background-repeat: no-repeat;  
}

.rosario-project{
    background-image: url('../../assets/images/desktop/project/rosarioCover.jpg');
    background-size: cover;
    background-position: 0% 50% ;
    background-repeat: no-repeat; 
}

.diverd-project{
    background-image: url('../../assets/images/desktop/project/diverdCover.jpg');
    background-size: cover;
    background-position: 0% 50% ;
    background-repeat: no-repeat; 
}

.add-stroke {
    -webkit-text-stroke-width: 1.25px;
    -webkit-text-stroke-color: black;
}

@media (max-width: 60em) {
    .old-soul-project {
        background-image: url('../../assets/images/mobile/project/mdOldSoulCover.jpg');
        background-size: cover;
        background-position: 0% 100% ;
        background-repeat: no-repeat;    
    } 

    .lantern-light-kitchen-project{
        background-image: url('../../assets/images/mobile/project/mdLanternLightKitchenCover.jpg');
        background-size: cover;
        background-position: 0% 100% ;        
        background-repeat: no-repeat;  
    }

    .rosario-project{
        background-image: url('../../assets/images/mobile/project/mdRosarioCover.jpg');
        background-size: cover;
        background-position: 0% 50% ;
        background-repeat: no-repeat; 
    }
    .diverd-project{
        background-image: url('../../assets/images/mobile/project/mddiverdCover.jpg');
        background-size: cover;
        background-position: 0% 50% ;
        background-repeat: no-repeat; 
    }
}

@media (max-width: 45em) {
    .old-soul-project {
        background-image: url('../../assets/images/mobile/project/smOldSoulCover.jpg');
        background-size: cover;
        background-position: 0% 100% ;
        background-repeat: no-repeat;    
    } 

    .lantern-light-kitchen-project{
        background-image: url('../../assets/images/mobile/project/smLanternLightKitchenCover.jpg');
        background-size: cover;
        background-position: 50% 100% ;
        background-repeat: no-repeat;  
    }
    .rosario-project{
        background-image: url('../../assets/images/mobile/project/smRosarioCover.jpg');
        background-size: cover;
        background-position: 50% 50% ;
        background-repeat: no-repeat; 
    }
    .diverd-project{
        background-image: url('../../assets/images/mobile/project/smdiverdCover.jpg');
        background-size: cover;
        background-position: 0% 50% ;
        background-repeat: no-repeat; 
    }
}