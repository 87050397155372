.old-soul {
    background-image: url('../../assets/images/desktop/home/oldSoul.jpg');
    background-size: cover;
    background-position: 0% 100% ;
    background-repeat: no-repeat;    
}

@media (max-width: 60em) {
    .old-soul {
        background-image: url('../../assets/images/mobile/Home/mdOldSoul.jpg');
        background-size: cover;
        background-position: 0% 100% ;
        background-repeat: no-repeat;    
    }
}

@media (max-width: 45em) {
    .old-soul {
        background-image: url('../../assets/images/mobile/Home/smOldSoul.jpg');
        background-size: cover;
        background-position: 0% 100% ;
        background-repeat: no-repeat;    
    }
}